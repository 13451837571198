import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ContactUsForm from "./../components/ContactUsForm";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";

const StayInTouchPage = () => {
  const stayInTouchQuery = useStaticQuery(graphql`
    {
      craftStayInTouchStayInTouchEntry {
        richTextField
        title
        callToAction
      }
    }
  `).craftStayInTouchStayInTouchEntry;
  const { title, richTextField, callToAction } = stayInTouchQuery;

  return (
    <Layout
      backgroundColor={"var(--pink)"}
      footerColor={"var(--pink)"}
      title={title}
    >
      <StandalonePageLayout>
        <h1>{title}</h1>
        <div>
          <WhiteBox>
            <div>
              <div dangerouslySetInnerHTML={{ __html: richTextField }} />
            </div>
          </WhiteBox>
          <ContactUsForm newsletter callToAction={callToAction} />
        </div>
      </StandalonePageLayout>
    </Layout>
  );
};

export default StayInTouchPage;
